


































import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({
  name: 'SectionContentAligned',
  components: {}
})
export default class extends Vue {
  @Prop({ default: () => ({}) }) service: any
  @Prop({ default: () => ({}) }) texts: any
  @Prop({ default: 'left' }) align: string
  @Prop() imgSrc: string
  @Prop({ default: () => ({}) }) imgProps: any

  get imgPropsMerged() {
    return Object.assign({}, { maxWidth: 400, maxHeight: 400 }, this.imgProps)
  }
}
