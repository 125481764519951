




import { Component, Vue, Prop } from 'nuxt-property-decorator'
import services from '~/data/services'

@Component
export default class extends Vue {
  @Prop() id: string
  @Prop() width: any
  @Prop() height: any
  @Prop({ default: false }) alt: any

  get src() {
    const service = services.find((service) => service.Key === this.id)
    const logo = this.alt && service.LogoAlt ? service.LogoAlt : service.Logo
    return require(`~/assets/img/logos/services/${logo}`)
    // return `/img/logos/services/${logo}`
  }
}
