




































































































































































































































































import { Component, Vue } from 'nuxt-property-decorator'
import PageSeoMixin from '~/mixins/PageSeoMixin'
import LogoService from '~/components/LogoService.vue'
import Logo from '~/components/Logo.vue'
import SectionContentAligned from '~/components/Section/SectionContentAligned.vue'
import TextCycle from '~/components/Other/TextCycle.vue'
import SectionLead from '~/components/Section/SectionLead.vue'
import { appModule } from '~/store'

@Component({
  name: 'HomePage',
  components: {
    LogoService,
    Logo,
    SectionContentAligned,
    TextCycle,
    SectionLead,
    HomeHeroVideo: () => import('~/components/Other/HomeHeroVideo.vue')
  },
  mixins: [PageSeoMixin]
})
export default class extends Vue {
  get words() {
    return [
      this.$tt(`Hero.Headlines.1`),
      this.$tt(`Hero.Headlines.2`),
      this.$tt(`Hero.Headlines.3`)
    ]
  }

  get isoList() {
    const items = [
      {
        Key: 'ISO9001',
        Name: 'ISO 9001:2016',
        Link:
          'https://www.tuvsud.com/en-sg/services/auditing-and-system-certification/iso-9001'
      },
      {
        Key: 'ISO17100',
        Name: 'ISO 17100:2015',
        Link: 'https://www.iso.org/standard/59149.html'
      },
      {
        Key: 'ISO18587',
        Name: 'ISO 18587:2017',
        Link: 'https://www.iso.org/standard/62970.html'
      }
    ]
    return items.map((item) => ({
      ...item,
      Img: require(`~/assets/img/iso/${item.Key.replace('ISO', 'ISO-')}.png`),
      Title: this.$tt(`QualityStandards.${item.Key}.Title`),
      Subtitle: this.$tt(`QualityStandards.${item.Key}.Subtitle`)
    }))
  }

  get memberList() {
    const items = [
      {
        Key: 'ATA',
        Img: 'ata.png',
        Link:
          'https://web.atanet.org/onlinedirectories/tsd_corp_listings/company_view.pl?id=1282'
      },
      {
        Key: 'ELIA',
        Img: 'elia.png',
        Link: 'https://elia-association.org/member/idioma/'
      },
      {
        Key: 'GALA',
        Img: 'gala.png',
        Link: 'https://www.gala-global.org/organization/idioma-sro'
      },
      {
        Key: 'ATC',
        Img: 'atc.png',
        Link: 'https://atc.org.uk/member/idioma/'
      },
      {
        Key: 'JTF',
        Img: 'jtf.png',
        Link: 'https://www.jtf.jp/connect/agency/17'
      },
      {
        Key: 'TEKOM',
        Img: 'tekom.png',
        Link: 'http://www.technical-communication.org/'
      },
      {
        Key: 'ADOBE',
        Img: 'adobe-technology-partner.png',
        Link:
          'https://partners.adobe.com/exchangeprogram/creativecloud/fragments/partners/a1X14000008lsrCEAQ.html'
      }
    ]
    return items.map((item) => ({
      ...item,
      Img: require(`~/assets/img/members/${item.Img}`)
    }))
  }

  get milestoneList() {
    const items = [
      {
        Key: 'Offices',
        Icon: 'far fa-building'
      },
      {
        Key: 'FileFormats',
        Icon: 'far fa-file'
      },
      {
        Key: 'Languages',
        Icon: 'fas fa-globe'
      },
      {
        Key: 'Translators',
        Icon: 'fas fa-users'
      },
      {
        Key: 'Combinations',
        Icon: 'fas fa-language'
      },
      {
        Key: 'WordsADay',
        Icon: 'far fa-calendar'
      }
    ]
    return items.map((item) => ({
      ...item,
      Text: this.$tt(`Milestines.${item.Key}`)
    }))
  }
}
