import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'

@Mixin
export default class extends Vue {
  head() {
    return {
      title: this.$t(`${this.$options.name}.SEO.Title`) + ' | ' + 'idioma®',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t(`${this.$options.name}.SEO.Description`)
        }
      ]
    }
  }
}
